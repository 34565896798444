
let re = new RegExp(/^[@A-Z\[0-9]*$/);

export const isSelectble = (char) => {
    if(re.test(char))
        return true;
    return false;
}


export const newSelection = (text, selection) => {
    let from = selection[0];
    let end = selection[1];
    
    while(from >= 0 ){
        if(text[from-1] != ' ' && re.test(text[from-1]) && from >= 0) from--
        else break;
    }

    while ( end <= text.length) {
        if(text[end] != ' ' && re.test(text[end]) && end <= text.length) end++
        else break;
    }
    
    return [from, end]
}