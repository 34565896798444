import React, {useState, useContext, useLayoutEffect, useRef, useEffect, memo} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DataContext } from '../../../utils/global/GlobalContext';
import translate from '../../../utils/Translate/translate';
import LangModal from './LangModal';
import { FiGlobe } from "react-icons/fi";

import styles from './Header.module.scss';

function Header(props) {
    const { translator, isMob, setTranslator } = useContext(DataContext);
    const [showLangModal, setShowLangModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const wrapperRef = useRef(null);
    const {lang} = useParams();
    const navigate = useNavigate();

    useLayoutEffect(()=>{
      if(translator.userlang != lang)
          setTranslator(new translate(lang));
  }, [lang]);


  useEffect(()=>{
    setShowLangModal(searchParams.get('lm'));
  },[searchParams.get('lm')]);

  useEffect(() => {
      const handleClickOutside = (event)=>{
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && showLangModal){

          showModal(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [showLangModal]);


    const changeLang = (newLang) => {
      if (translator.userlang != newLang)
        navigate(`/${newLang}`);
      showModal(!showLangModal)
    }

    const showModal = (show) =>{
      if(isMob){
        if (show) {
          searchParams.set("lm", true);
          setSearchParams(searchParams);
        } else {
          searchParams.delete('lm');
          setSearchParams(searchParams);
        }
      }
      setShowLangModal(show);
    }

    return (
          <main-header>


              <div className={styles.logo}>
                  R[JZNDA
              </div>
              <div ref={wrapperRef}>
                <button onMouseDown={()=>showModal(!showLangModal)}>
                    <FiGlobe />
                </button>

                <LangModal show={showLangModal} onClick={changeLang} close={()=>showModal(false)} />

              </div>

          </main-header>
    );
}

export default memo(Header, (prePosps, nextPos)=>
    (prePosps.lang == nextPos.lang)
);
// export default Header
