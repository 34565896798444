
const Blocks = {
        "block_ctrl": {
            "title": "بلاک کنترلی",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_eriobase": {
            "title": "بلاک ایریو",
            "short_des": "",
            "subBlock": [
                "علامتها",
                "شماره‌ها",
                "علامتها",
                "الفبای ایریو",
                "علامتها",
                "شماره‌های مبنای شانزده",
                "علامتها"
            ],
            "des": []
        },
        "block_ctrl2": {
            "title": "بلاک کنترلی دوم",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_erio_extnd": {
            "title": "افزونه‌ی ایریو",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_avestan": {
            "title": "دین‌دبیره (اوستایی)",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_pahlavi": {
            "title": "پهلوی (فارسی میانه)",
            "short_des": "",
            "subBlock": [
                "حروف بزرگ",
                "شماره‌های یک",
                "حروف کوچک",
                "افزونه‌ی زبور پهلوی",
                "افزونه‌های اشکانی",
                "شماره‌های دوم",
            ],
            "des": []
        },
        "block_mikhi": {
            "title": "میخی",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_latin1": {
            "title": "لاتین",
            "short_des": "",
            "subBlock": [],
            "des": []
        }
}

export default Blocks;