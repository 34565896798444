import React from 'react';
import styles from './Section.module.scss';

const Section =(props) =>(
    <section-word className={"(props.tr_data.unicode.to &&'is_unicode')"}>
          <h3>{props.data.author}</h3>
          {
            props.data.meanings.map((data, index) => (
              <div key={data.author+data.lang+index}>
                 {!!(data.author) && <h3 id={data.author}>{data.author}</h3>}
                <p dangerouslySetInnerHTML={{ __html: data }} />
              </div>
            ))
          }
      </section-word>
)


export default Section
