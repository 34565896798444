import eriotyper, {UniCodeCode, ErioCode} from "eriotyper";


export const typer = new eriotyper({inputClass : ['erio_input']});
export const terminalTyper = new eriotyper({inputClass : ['erio_input']}, UniCodeCode.EN, ErioCode.EN);


export const changeInput = (newInput) =>{
    typer.changelang(UniCodeCode.EN, newInput, false);
};

export const resetErio = () =>{
    typer.action();
};

export const termReturnString = (str)=>{
    let newStr = '';
    for (let index = 0; index < str.length; index++) {
        let a = terminalTyper.stdOutput.indexOf(str[index]);
        newStr += a != -1 ? terminalTyper.userInput[a] : str[index];
    }
    return newStr;
}

export const stringToErio = (e)=>{
    if (e.which) {
        let charStr = String.fromCharCode(e.which);
        let transformedChar = typer.transformTypedCharacter(charStr);
  
          if (transformedChar != charStr) {
            let wholeText = e.target.value;
            let posStart = e.target.selectionStart
            let posEnd = e.target.selectionEnd
            let output = [wholeText.slice(0, posStart), transformedChar, wholeText.slice(posEnd)].join("");
            //update cursor for bigger unicode char
            let CP = (transformedChar.charCodeAt(0)>255)?2:1;
            
            
            return [output,  e.target.selectionEnd = posStart+CP];
          };
    };
}


