import { useState, useEffect, useMemo } from 'react';
// import MainPage from './Components/MainPage'
// import {settings as settingOptions} from './config/settings';
import { DataContext } from './Components/utils/global/GlobalContext';
import { langsList as trData } from './Components/utils/Translate/supportedLang';
import translate from './Components/utils/Translate/translate';
import AppRouters from './Components/AppRouters';
import './App.scss';

function App() {

  const [translator, setTranslator] = useState(new translate());
  const [fromTr, setFromTr] = useState(trData[1]);
  const [toTr, setToTr] = useState(trData[0]);

  // =====================================================
  // =====================================================
  const isMob = useMemo(()=>{
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    // console.log('========================');
    // console.log(window.navigator.userAgent);
    return toMatch.some((toMatchItem) => (
        window.navigator.userAgent.match(toMatchItem)
    ));
  }, [])

  useEffect(() => {
    console.log(translator.userlang);
    // if(configs.settings.core.options.typingStandardization){
    //   typer.action();
    //   changeInput(configs.settings.core.options.keyboardLang);
    // }else{
    //   typer.deactive();
    // }
    // setTranslator(new translate(configs.settings.core.options.uiLang));
  }, [translator]);

  return (
    <DataContext.Provider  value={{translator, isMob, setTranslator, fromTr, setFromTr, toTr, setToTr}}>
      <div className={`App ${(translator.userlang==='CL')?'erio':''}`} spellCheck='false'>
        <AppRouters />
      </div>
    </DataContext.Provider>
  );
}

export default App;
