import { createContext } from 'react'
// import reportWebVitals from './reportWebVitals';

export const DataContext = createContext({
    authenticated: false,
    setAuthenticated: (auth) => {}
  });
export const TableContext = createContext('');


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
