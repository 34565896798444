import header from './header.json';
import keyboard from './keyboard.json';
import Search from './search.json';
import words from './words.json';
import { FiChevronLeft } from "react-icons/fi";

const item = {
    "copyBtn": "رونوشت",
    "eriocodeBlock": "بلاک",
    "eriocodeNum": "شماره‌ی کد",
    "toLang": <FiChevronLeft />
}

const Items = Object.assign({}, header, keyboard, Search, words, item)

export default Items;