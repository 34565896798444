const erio = {
  default: [
    "X M O R D V T B N I",
    "Y K J [ L Z A P E @",
    "G Q F W U S C H backspace",
    "' space return"
  ],
  shift: [
    "] < > l $ % s & * ( ) _ + backspace",
    "tab X M O R D V T B N I { } :",
    'capslock Y K J [ L Z A P E @ " return',
    "shift G Q F W U S C H , ? shift",
    "ctrl cmd alt space alt cmd ctrl",
  ],
  alt: [
    "\\ a b c d e f g h i ` ^ z backspace",
    "tab X M O R D V T B N I m o #",
    "capslock Y K J [ L Z A P E @ x return",
    "shift G Q F W U S C H q k shift",
    "ctrl cmd alt space alt cmd ctrl"
  ],

};

export default erio;
