
import { useContext } from 'react';
import { DataContext } from '../utils/global/GlobalContext';
import styles from './GlobeText.module.scss';

const GlobeText = (props)=>{

    const {fromTr} = useContext(DataContext);
    return(
        <span className={props.eriocode? styles.eriocode:''}>{props.children}</span>
    )
}

export default GlobeText;