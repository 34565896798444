import header from './header.json';
import keyboard from './keyboard.json';
import Search from './search.json';
import words from './words.json';
import { FiChevronRight } from "react-icons/fi";

const item = {
    "copyBtn": "Ǥǽǿȑ",
    "eriocodeBlock": "ǢǷǽǥǵ",
    "eriocodeNum": "ǨȃǱǽǥǽǧǩ ǻȉǹǣǩȃ",
    "toLang": <FiChevronRight />
}

const Items = Object.assign({}, header, keyboard, Search, words, item)

export default Items;