import mainPage from './main.json'
import aboutPage from './about.json';
import alphabetPage from './alphabet.json';
import keyboardPage from './keyboard.json';
import playgroundPage from './playground.json'; 


const Pages = {mainPage, aboutPage, alphabetPage, keyboardPage, playgroundPage};


export default Pages;