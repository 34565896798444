const english = {
  default: [
    "q w e r t y u i o p",
      "a s d f g h j k l",
      "shift z x c v b n m backspace",
      "' space return"
  ],
  shift: [
    "Q W E R T Y U I O P",
      "A S D F G H J K L",
      "shift Z X C V B N M backspace",
    "' space return"
  ],
};

export default english;
