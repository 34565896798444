import {ErioCode, UniCodeCode} from "eriotyper";


export const langsList = [
    {
        id: ErioCode.CL,
        icon: 'CL',
        key: 'CL',
        rtl: false,
        eriocode: true,
        title: 'CALN[T'
    },
    {
        id: UniCodeCode.EN,
        icon: 'Ǩǻ',
        key: 'u@Y',
        rtl: false,
        eriocode: false,
        title: 'ǨǻǭǷǱȅǯ'
    },
    {
        id: UniCodeCode.FA,
        icon: 'فا',
        key: 'uQL',
        rtl: true,
        eriocode: false,
        title: 'فارسی'
    },
    {
        id: UniCodeCode.AV,
        icon: '𐬬',
        key: 'u@R',
        rtl: true,
        unicode: true,
        title: '𐬀𐬬𐬆𐬯𐬙𐬁𐬌𐬌'
    },
];

export const findIdByTitle = (title) => {
    
    let lang = langsList.filter((ln)=>(
        ln.title === title
    ))[0]
    if(lang) return lang.id;
    else return 'en';
};


export const base64_fix = (num)=>{
    let lNum = 'abcdef';
    let eNum = '`abcde'
    let newNum = ''
    for (let n of num) {
        let index = lNum.indexOf(n);
        // console.log(n, index);
        newNum += (index>-1)?eNum[index]:n;
    };
    return newNum
};