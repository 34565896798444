import menu from './menu.json';
import Pages from './Pages';
import Blocks from './Blocks';
import Symbols from './Symbols';
import Items from './Items';

const sources = Object.assign({}, menu, Pages, Blocks, Symbols, Items);

const lang = {

    "title": "𐬀𐬬𐬆𐬯𐬙𐬁𐬌𐬌",
    "description": "",
    "unicode": true,
    "rtl": true,
    sources
}

export default lang;