
import styles from './Loading.module.scss';

const Loading = (props) => {

    return(
        <loading-resault class={`${styles.loader} ${(props.show && styles.showLoader)}`}>
              <div className={styles.box}>
                <div className="loader-04"></div>
              </div>
        </loading-resault>
    )
}


export default Loading;