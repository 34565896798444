
const Blocks = {
        "block_ctrl": {
            "title": "QZLXAYIA",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_eriobase": {
            "title": "@[L[E (CA[Z)",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_ctrl2": {
            "title": "JEREX[Y QZLXAYIA",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_erio_extnd": {
            "title": "@[L[E (@KQMPJZ)",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_avestan": {
            "title": "J[YJKB[LZ",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_pahlavi": {
            "title": "CKIVKR[ (CALN[T)",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_mikhi": {
            "title": "CALN[T'Z X[W[T",
            "short_des": "",
            "subBlock": [],
            "des": []
        },
        "block_latin1": {
            "title": "VAD[Y",
            "short_des": "",
            "subBlock": [],
            "des": []
        }
}

export default Blocks;