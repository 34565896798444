import {CL, fa, en, av} from '../../../config/locales';


export default class translate {
    constructor(lang){
        this.resources = { CL, fa, en, av }
        if(this.resources[lang])
            this.userlang = lang;
        else
            this.userlang = 'en'
        
        this.langList = this.makeLangList();
    }
    resources;
    userlang;
    langList;
        

    makeLangList = ()=>{
        let keys = Object.keys(this.resources);
        let lang_list = [];
        keys.map((name)=>{
            lang_list.push({
                key: name,
                title: this.resources[name].title,
                description: this.resources[name].description,
                unicode: this.resources[name].unicode
            })
        });
        return lang_list;
    }


    _tr = (text) => {
        if (!this.userlang) {
            return text;
        } else {
            try {
                let translated = this.resources[this.userlang].sources[text]
                if(translated) return translated; else return text;
            } catch (error) {
                console.log('wrong lang!...');
            }
        }
    };


    isUnicode = () => {
        try {
            let unicode = this.resources[this.userlang].unicode
            if(!!unicode) return true; else return false;
        } catch (error) {
            console.log('wrong lang!...');
        }
    };

    isRtl = () => {
        try {
            let isRtl = this.resources[this.userlang].rtl
            if(!!isRtl) return true; else return false;
        } catch (error) {
            console.log('wrong lang!...');
        }
    }


    langsList = () => {
        return this.langList;
    }
}


