const avestan = {
  default: [
    "𐬆 𐬢 𐬱 𐬈 𐬭 𐬙 𐬫 𐬎 𐬌 𐬊 𐬞",
    "𐬁 𐬀 𐬯 𐬛 𐬟 𐬔 𐬵 𐬘 𐬐 𐬮 𐬄",
    "shift 𐬰 𐬑 𐬗 𐬬 𐬠 𐬥 𐬨 backspace",
    "𐬼 space return"
  ],
  shift: [
    "𐬇 𐬣 𐬴 𐬉 𐬦 𐬚 𐬪 𐬏 𐬍 𐬋 𐬞",
    '𐬃 𐬂 𐬳 𐬜 𐬖 𐬕 𐬵 𐬤 𐬐 𐬮 𐬅',
    "shift 𐬲 𐬒 𐬝 𐬓 𐬡 𐬧 𐬩 backspace",
    "𐬼 space return"
  ],
};

export default avestan;
