
import { useContext, useMemo, useState } from 'react';
import { DataContext } from '../utils/global/GlobalContext';
import {erio, avestan, english, farsi} from '../utils/Keyboards'
import styles from './KeyboardLayout.module.scss';

const KeyboardLayout = (props) => {

    const {translator, fromTr} = useContext(DataContext);
    const [shift, setShift] = useState(props.shift);
    const [alt, setAlt] = useState(props.alt);

    const keyPress = (k)=>{
        if(k == 'shift')
            setShift(!shift);
        else if (k =='alt')
            setAlt(!alt);
        else{
            props.onKeyPress(k);
            setShift(false);
            setAlt(false);
        }
    }

    const keyType = useMemo(()=>(shift?'shift': alt?'alt':'default'), [shift,alt]);
    const keyboardLang = useMemo(()=>{
        switch (fromTr.id) {
            case 'CL':
                return erio;
            case 'en':
                return english;
            case 'fa':
                return farsi;
            default:
                return avestan;
        }
    }, [fromTr]);

    return(
        <keyboard-layout>
            <div className={`${styles.keyboardBase} ${!fromTr.eriocode?styles.isUnicode:''} ${props.className} ${shift?styles.shiftActive:''}`}>
                {/* {keies.map((k)=>(
                    <div className={`${styles.key} ${styles[k.type]}`}>{k.key}</div>
                ))} */}
                {keyboardLang[keyType].map((row, i)=>(
                    <div className={styles.row} key={i}>
                        {row.split(' ').map((k, i)=>(
                            <div tabIndex={0} onClick={()=>keyPress(k)}
                                className={`${styles.key} ${(k.length>1)?styles[k]:''} ${(keyType===k)?styles.active:''}`} 
                                        key={k}>{(k.length>1 && k in props.display)?props.display[k]:k}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </keyboard-layout>
    )
}

export default KeyboardLayout;