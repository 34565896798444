import React from 'react';

import MainPage from './MainPage/MainPage';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/:lang",
    element: <MainPage />,
    // children: [
    //   {
    //     path: ":word",
    //     element: <AboutCode />,
    //   }
    // ]
  },

  {
    path: "*",
    element: <NoMatch />,
  },
  
]);



const AppRouters = () => (
  <RouterProvider router={router} />
);



function NoMatch() {
  return <h2>404</h2>;
}


export default AppRouters
