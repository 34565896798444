import React, { memo, useContext } from 'react';
import { DataContext } from '../../../utils/global/GlobalContext';
import GlobeText from '../../../Items/GlobeText';
import styles from './SimilarWord.module.scss';

const SimilarWord = (props) => {
  const {fromTr} = useContext(DataContext);
    return (
      <div className={styles.similarWord} onClick={() => props._callBack(props.data.word)}>
          <h2>
            <GlobeText eriocode={fromTr.eriocode}>{props.data.word}</GlobeText>
          </h2>
          {
            props.data.translations[0].meanings.map((data, index) => (
              <div key={data.author+data.lang+index}>
                 {!!(data.author) && <h3 id={data.author}>{data.author}</h3>}
                <p dangerouslySetInnerHTML={{ __html: data }} />
              </div>
            ))
          }
      </div>

    );
}


// export default SimilarWord
export default memo(SimilarWord, (prePosps, nextPos)=>
    (prePosps.data == nextPos.data)
);