import menu from './menu.json';
import Pages from './Pages';
import Blocks from './Blocks';
import Symbols from './Symbols';
import Items from './Items';

const sources = Object.assign({}, menu, Pages, Blocks, Symbols, Items);


const lang = {
    "title": "ǨǻǭǷǱȅǯ",
    "description": "",
    "unicode": false,
    "rtl": false,
    sources
}

export default lang;