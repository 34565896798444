
const Blocks = {
    "block_ctrl": {
        "title": "First Ctrl",
        "short_des": "",
        "subBlock": [],
        "des": []
    },
    "block_eriobase": {
        "title": "Basic Erio Alphabet",
        "short_des": "",
        "subBlock": [
            "punctuation and symbols",
            "digits",
            "punctuation and operators",
            "erio alphabet",
            "punctuation and symbols",
            "hex digits",
            "punctuation and symbols"
        ],
        "des": [
            "Donec id pellentesque mi, at malesuada lacus. Quisque risus mi, ultrices quis nibh sit amet, lacinia suscipit mi. Maecenas velit ante, venenatis non risus quis, dapibus molestie nisl. Praesent quam leo, viverra eget suscipit in, finibus vel nisi. Integer sit amet ipsum ac sapien aliquam fringilla. Donec molestie hendrerit metus, nec cursus risus feugiat ut. Pellentesque eleifend, nunc et gravida lobortis, lacus ante laoreet leo, in ullamcorper mauris enim et augue. Praesent vestibulum vestibulum erat at ultricies. Duis mi nulla, luctus eu elit et, volutpat fringilla arcu. Donec tristique non purus a semper. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla eu faucibus orci. Fusce sed mi a neque ultricies venenatis"
        ]
    },
    "block_ctrl2": {
        "title": "Second Ctrl",
        "short_des": "",
        "subBlock": [],
        "des": []
    },
    "block_erio_extnd": {
        "title": "Extended Erio Alphabet",
        "short_des": "",
        "subBlock": [],
        "des": []
    },
    "block_avestan": {
        "title": "Avestan",
        "short_des": "",
        "subBlock": [],
        "des": []
    },
    "block_pahlavi": {
        "title": "Pahlavi (Middle Persian)",
        "short_des": "",
        "subBlock": [
            "Uppercase",
            "Numbers 1",
            "Lowercase",
            "Psalter extended",
            "Parthian extended",
            "Numbers 2",
        ],
        "des": []
    },
    "block_mikhi": {
        "title": "Old Persian",
        "short_des": "",
        "subBlock": [],
        "des": []
    },
    "block_latin1": {
        "title": "Latin",
        "short_des": "",
        "subBlock": [],
        "des": []
    },
}

export default Blocks;