const Symbols = {Symbols : [{"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {
        "name":"Space",
        "des":[
            "Space is basically the amount of free place between letters, separating words in a text.",
            "First scripts were pictographic or ideographic. Each symbol stood for a word, and it wasn't necessary to separate them. Since alphabets got introduced, reading a monolith text became inconvenient. That's how new special symbols appeared, the purpose of which was to divide words. The symbol of Space, now considered standard, wasn't a one-day invention. As for Latin and Greek scripts, Space had already been used there for around one thousand years. However, Cyrillic script took a bit longer to adapt. There it got employed only in the XVII century. Speaking of Arabic, spaces appeared there a bit later, in the XX century.",
            "In addition to this special symbol, word separation can be indicated in other ways. For example, using special letter forms for the end or beginning of a word. In the Arabic alphabet, several letters exist in four different forms of writing (for the beginning, end, middle, and separate forms). Although Arabs use spaces, letters still have different forms. Another alternative is a line above the letters. The words themselves are written without spaces, and the line is interrupted. In some writing systems, it may be that not words, but phrases, sentences, or syllables are separated. The true space is used in almost all modern writing systems. In Thai, only sentences are separated by spaces."
        ]
    },
    {
        "name":"Left Square Bracket",
        "des":[
            "Left Square Bracket. ",
            "The symbol “Left Square Bracket” is included in the “ASCII punctuation and symbols” Subblock of the “Basic Latin” Block and was approved as part of Unicode version 1.1 in 1993."
        ]
    },
    {
        "name":"Quotation Mark",
        "des":[
            "Quotation Mark.",
            "The symbol “Quotation Mark” is included in the “ASCII punctuation and symbols” Subblock of the “Basic Latin” Block and was approved as part of Unicode version 1.1 in 1993."
        ]
    },
    {
        "name":"Darik Sign",
        "des":[]
    },
    {"name":"","des":[]},
    {"name":"Percent Sign","des":[]},
    {"name":"Erio And Sign","des":[]},
    {"name":"Apostrophe","des":[]},
    {"name":"Left Parenthesis","des":[]},
    {"name":"Right Parenthesis","des":[]},
    {"name":"","des":[]},
    {"name":"Plus Sign","des":[]},
    {"name":"Comma","des":[]},
    {"name":"Hyphen-Minus","des":[]},
    {"name":"Full Stop","des":[]},
    {"name":"Solidus","des":[]},
    {"name":"Digit Zero","des":[]},
    {"name":"Digit One","des":[]},
    {"name":"Digit Two","des":[]},
    {"name":"Digit Three","des":[]},
    {"name":"Digit Four","des":[]},
    {"name":"Digit Five","des":[]},
    {"name":"Digit Six","des":[]},
    {"name":"Digit Seven","des":[]},
    {"name":"Digit Eight","des":[]},
    {"name":"Digit Nine","des":[]},
    {"name":"Colon","des":[]},
    {"name":"Semicolon","des":[]},
    {"name":"Less-Than Sign","des":[]},
    {"name":"Equals Sign","des":[]},
    {"name":"Greater-Than Sign","des":[]},
    {"name":"Question Mark","des":[]},
    {"name":"Erio ey Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},
    {"name":"Erio -- Letter","des":[]},

    {"name":"Reverse Solidus","des":[]},
    {"name":"Vertical Line","des":[]},
    {"name":"ine","des":[]},
    {"name":"Low Line","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
    {"name":"","des":[]},
]};

export default Symbols;