const farsi = {
  default: [
    "ص ث ق ف ع ه خ ح ج چ",
    "ش س ی ب ل ا ت ن م ک گ",
    "shift ط ز ر ذ د پ و backspace",
    "space return"
  ],
  shift: [
    "ض ث ق ف غ ه خ ح ج چ",
    "ش س ی ب ل آ ت ن م ک گ",
    "shift ظ ژ ر ذ د پ و backspace",
    "space return"
  ],
};

export default farsi;
