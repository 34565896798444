
import { useContext } from 'react';
import { DataContext } from '../../../utils/global/GlobalContext';
import { langsList } from '../../../utils/Translate/supportedLang';
import { useLocation, useNavigate } from 'react-router-dom';
import translate from '../../../utils/Translate/translate';
import { FiX } from 'react-icons/fi';
import styles from './LangModal.module.scss';


const LangModal = (props) => {

    const { translator, setTranslator } = useContext(DataContext);
    
    const navigate = useNavigate();
    const location = useLocation();
    let isUni
    // for (const [key, value] of Object.entries(lang_list())) {
    //     isUni = (is_unicode(key))?" is_unicode":""
    //     langs.push(<div key={key} className={'lang_link ' + (key == props.lang && 'link_selected ')  + isUni } onClick={ () => changeLang(key)}>{value.translations.uiInfo.showName}</div>);
    // }



    const setUILang = (lang_id)=>{
        // dgarguni baxshe parame lang dar url be parame no
        let newPath = location.pathname.split('/').map((p, i)=>(
            (i==1)?lang_id:p
        )).join('/')
        navigate(newPath);
        setTranslator(new translate(lang_id));
        props.close();
    }

    return(
        <lang-modal class={(props.show && styles.show)} >
            <div className={styles.back} onClick={props.close}></div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <button className={styles.close} onClick={props.close}><FiX /></button>
                </div>
                <div className={styles.langsList}>
                    {langsList.map((lang, i)=>(
                        <span key={i} 
                            className={translator.userlang==lang.id?styles.active:''}
                            onClick={()=>setUILang(lang.id)}
                        >
                            {lang.title}
                        </span>
                    ))}

                </div>
            </div>
        </lang-modal>
    )
};

export default LangModal;