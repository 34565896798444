import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DataContext } from "../utils/global/GlobalContext";
import KeyboardLayout from "./KeyboardLayout";
import { newSelection } from "../utils/Translate/selecting";
import { FaRegKeyboard } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { BsShiftFill, BsBackspaceFill } from "react-icons/bs";
import { changeInput, typer } from "../utils/global/GlobalTyper";
import { langsList as trData } from '../utils/Translate/supportedLang';
import styles from './SerachInput.module.scss';
import ChangeTr from "./ChangeTr";


const SearchInput = (props) => {
    
    const inputRef = useRef(null);
    const [showKB, setShowKB] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const {translator, isMob, fromTr, setFromTr, toTr, setToTr} = useContext(DataContext);
    
    const [input, setInput] = useState(props.input);
    const [select, setSelect] = useState(0);
    const [trLangModal, setTrLangModal] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(()=>{
        setShowKB(searchParams.get('kb'));

        const handleClickOutside = (event)=>{
            console.log(wrapperRef.current && !wrapperRef.current.contains(event.target));
          if (wrapperRef.current && !wrapperRef.current.contains(event.target) && isMob){
              showKBFn(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    },[searchParams]);

    useEffect(() => {
        inputRef.current.focus();
        // console.log(select);
        inputRef.current.setSelectionRange(select, select);
    }, [inputRef?.current?.value]);


    useEffect(()=>{
        changeInput(fromTr.id);
    }, [fromTr])


    useEffect(() => {
        if(inputRef.current.value != props.input)
            inputRef.current.value = props.input;
    }, [props.input])


    useLayoutEffect(()=>{
        let fl = searchParams.get('fl');
        if(fl && fromTr.key != fl)
            setFromTr(trData.find((lang)=>(lang.key == fl)));
            
        let tl = searchParams.get('tl');
        if(tl && toTr.key != tl)
            setToTr(trData.find((lang)=>(lang.key == tl)));

        let query = searchParams.get('q')
        props.onSubmit(query, fl, tl);
    },[searchParams]);


    let showKeyboard = () => {
        showKBFn(showKB ? 0 : 1);
    }

    const onKBPress = (key) => {
        window.navigator.vibrate(4);
        // let pos = [inputRef.current.selectionStart,inputRef.current.selectionEnd]
        if (key === "return") {
            showKBFn(0);
            props.onSubmit(inputRef.current.value);
        }
        else if(key === "backspace"){
            insertChar('', true);
        }
        else{
            if(key === "space")
                key = ' ';
            insertChar(key);
        }
    }

    const insertChar = (val, back=false)=>{
        // console.log(val.charAt(val.length - 1));
        let pos = [inputRef.current.selectionStart,inputRef.current.selectionEnd]
        if(isMob) pos = [inputRef.current.value.length, inputRef.current.value.length]

        let caret = 1;
        // console.log(inputRef.current.value.charCodeAt(pos[0]-1));
        if(back) caret = (inputRef.current.value.charCodeAt(pos[0]-1)>2000)?-2:-1;
        setSelect(pos[0]+(caret>-1?val.length:caret))
        let text =inputRef.current.value.substring(0, pos[0]+(caret>-1?0:caret))
        + val + inputRef.current.value.substring(pos[1], inputRef.current.value.length);
        inputRef.current.value = text;
    }


    const show_TrLangModal = () => {
        setTrLangModal(!trLangModal)
      }

    const submit = ()=>{
        showKBFn(false);
        props.onSubmit(inputRef.current.value);
    }

    const inputTyping = (e) =>{
        if(!e.ctrlKey){
            let target = typer.hndlKeyPress(e)?.target;
            if(e.key =='Enter')
                submit();
            else if(target && target.value){ 
                inputRef.current.value = target.value;
                setSelect(target.selectionStart);
            };
        }
    }

      // ++++++++++++++++++++++++ 
    const handleDbClick = (e)=>{
        // agar kelide chap bud o db click
        if(e.button==0 && e.detail==2){
            // agar erio code nevise bud   
            if(fromTr.eriocode){
                //  select khodkar ro az kar bendaz
                e.preventDefault();
                let [from, end] = newSelection(
                    inputRef.current.value, 
                    [inputRef.current.selectionStart, inputRef.current.selectionEnd]
                );
                inputRef.current.setSelectionRange(from, end);
            }
        }
    }

    const showKBFn = (show) =>{
        if(isMob){
            if (show) {
              searchParams.set("kb", true);
              setSearchParams(searchParams);
            } else {
              searchParams.delete('kb');
              setSearchParams(searchParams);
            }
        }
        setShowKB(show);
    }

    return(
        <search-input class={props.className}>
            <div ref={wrapperRef}>
                <div className={`${styles.inputBox} ${!translator.isRtl()?styles.ltrPage:''}`}>
                    <button type="button" className={styles.btnKB} onClick={showKeyboard}>
                        <FaRegKeyboard style={{ fill: (showKB)? '#0303b0' : '#6a6969'}}/>
                    </button>
                    <div className="choices" data-type="text" aria-haspopup="true" aria-expanded="false">
                        <div className={`${styles.input} ${!fromTr.eriocode?styles.isUnicode:''} ${fromTr.rtl?styles.isRTL:''}`} 
                            onClick={isMob?()=>showKeyboard():null}
                        >

                            <input type="text" name="q" readOnly={isMob} 
                                className={'erio_input'} onKeyDown={inputTyping} onMouseDown={handleDbClick}
                                placeholder={translator._tr('serchPlaceholder')} autoComplete="off" ref={inputRef}
                                onFocus={props.focus}/>
                            <span className={showKB?styles.active:''} role="textbox">{inputRef?.current?.value}<s-sign> </s-sign></span>
                        </div>

                    </div>
                    <button type="button" onClick={show_TrLangModal}
                            className={"btn-lang btn-lang-dir"}>
                                <ChangeTr 
                                    setKB={(e)=>showKBFn(e)}
                                    clearInput={()=>inputRef.current.value = ''}
                                />
                    </button>
                    <button type="button" onClick={submit}
                            className={"btn-search btn-search-dir"}>
                        <FiSearch />
                    </button>
                </div>

                <KeyboardLayout 
                    className={`${styles.keyboard} ${(showKB)?styles.show:''}`}
                    display= {{
                        "return": translator._tr('return'),
                        "backspace": <BsBackspaceFill />,
                        "shift": <BsShiftFill />,
                        "space": ""
                    }}
                    lang={'CL'}
                    onKeyPress={onKBPress}
                />
            </div>
        </search-input>
    )
}

export default SearchInput;