import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSearchParams, useLocation} from 'react-router-dom'
import { DataContext } from '../../utils/global/GlobalContext';
import Header from './Header/Header'
import SearchInput from '../../Items/SerachInput';
import WordResult from './WordResult/WordResult'
import {UniCodeCode, ErioCode} from "eriotyper";
import {api_server} from '../../../config/settings';
import styles from './SearchSection.module.scss';
import Loading from '../../Items/Loading';
// =====================================================
// =====================================================


const SearchSection = (props) => {


  // const history = useSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const {translator, fromTr, toTr} = useContext(DataContext);
  // const { replace } = useHistory();
  let query = searchParams.get('q');

  const emptyState = {
    best_word: {},
    same_roots: [],
    similar_list: [],
    showWord: false
  }


  const [input, setInput] = useState(query?query:"");
  const [searchMode, setSearchMode] = useState(false);
  const [loadingMode, setLoadingMode] = useState(false);
  const [state, setState] = useState(emptyState);


    // =====================================================
    // =====================================================
    const word_search = (key, from=null, to=null) => {
      // setShowKB(0)
      if (key) {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setSearchMode(true)
        setLoadingMode(true)

        // let from_lang = unicodeLangId[trData.from]?unicodeLangId[trData.from]:trData.from;
        let from_lang = from || fromTr.key;
        // let to_lang = unicodeLangId[trData.to]?unicodeLangId[trData.to]:trData.to;
        let to_lang = to || toTr.key;

        fetch(api_server+'/api/v1/word/', {
              method: 'POST',
              // mode: 'no-cors',
              headers: {
                Accept: 'application/json',
                        'Access-Control-Allow-Origin':'*',
                        'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                search_keys : key.trim(),
                from_lang : from_lang,
                to_lang: to_lang
              }),
            }).then((response) => {
                  return response.json();
              })
              .then(data => {
                setLoadingMode(false)
                setState({
                  best_word: data['search']['best_word'],
                  same_roots: data['search']['same_roots'],
                  similar_list: data['search']['similar_list'],
                  showWord: true
                });
              })
              .catch((error) => {
                    setLoadingMode(false)
                    setState({
                      ...emptyState,
                      showWord: true
                    });
                    
              });
            
      }else{
        setSearchMode(false)
        setLoadingMode(false)
        setState({
          ...emptyState,
          showWord: true
        });
      }
    }


    const setParam = (key) => {
      searchParams.set("tl", toTr.key);
      searchParams.set("fl", fromTr.key);
      key && searchParams.set("q", key.trim());
      setSearchParams(searchParams);
    }

  // =====================================================
  // =====================================================
      const click_word = (key) => {
        setInput(key);
        setParam(key);
        word_search(key)
      }
  // =====================================================
  // =====================================================

  const submit = (newInput, from=null, to=null) => {
    if(newInput && (!from || !to)){
      setParam(newInput);
    } 
    setNewWord(newInput, from, to);
    setInput(newInput);
    
  }

  const setNewWord = (word, from, to) => {
    setState({...state, showWord: false});
    if(word)
      word_search(word, from, to);
    else{
      setSearchMode(false);
      setLoadingMode(false);
    }
  }

  // =====================================================
  // =====================================================

  let onKeyPress = (button) => {
  }


  // =====================================================
  // =====================================================
  return(
      <search-section>
        <Header lang={props.lang} />

          <Loading show={loadingMode} />
          <div className={`${styles.form} ${(searchMode ? styles.searchMode : '')}`}>


            <div>
              <h3 className={styles.headeTitle}>{translator._tr("mainPage").title}</h3>
              
              <SearchInput 
                className={styles.searchInput} 
                // langs={{to:toTr, from:fromTr}}
                input={input}
                onSubmit={submit} 
                // onChange={onChangeInput}
                onKBPress={onKeyPress}
              />

            </div>

            <div>{state.showWord}</div>
            <WordResult lang={props.lang} loadingMode={loadingMode} wordState={state} tr_data={[]} _callBack={click_word}/>
          </div>

      </search-section>

  );
}

export default SearchSection
