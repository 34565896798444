import React, { useState, useRef, useContext }  from 'react';
import { DataContext } from '../../../utils/global/GlobalContext';
import Section from './Section'
import SimilarWord from './SimilarWord'
import styles from './WordResult.module.scss';
import Loading from '../../../Items/Loading';
import GlobeText from '../../../Items/GlobeText';



// class WordResult extends React.Component {
const WordResult = (props)=>{

  const {translator, fromTr, toTr} = useContext(DataContext);

  return (
      <word-result>

            {  (!!Object.keys(props.wordState.best_word).length)?
            <div className={`${styles.bestWord} ${(props.wordState.showWord && styles.showWord)}`}>

                <h1 className={"(!props.tr_data.unicode.from)?'is_eriocode':''"}>
                  <GlobeText eriocode={fromTr.eriocode}>{props.wordState.best_word.word}</GlobeText>
                </h1>

                <div className={styles.wrdFeatures}>
                  {
                    (!!props.wordState.best_word.translations && props.wordState.best_word.translations.length)?
                      <div>
                          {(props.wordState.best_word.type[0] in ["1", "2", "6", "@"])?
                            <div>
                                <span className="wordRole">{translator._tr('GrammaticalRole')} </span>
                                <div className="wordRole">
                                    {(props.wordState.best_word.type[0])?<span>{translator._tr('GrammaticalRoleType'+props.wordState.best_word.type[0])} </span>:null}
                                    {(props.wordState.best_word.type[1])?<span>{translator._tr('GrammaticalRoleNumber'+props.wordState.best_word.type[1])} </span>:null}
                                    {(props.wordState.best_word.type[2])?<span>{translator._tr('GrammaticalRoleCase'+props.wordState.best_word.type[2])} </span>:null}
                                    {(props.wordState.best_word.type[3])?<span>{translator._tr('GrammaticalRoleGender'+props.wordState.best_word.type[3])} </span>:null}
                                </div>
                            </div>
                          :null}
                      </div>
                    :null
                  }
                  {
                    (!!props.wordState.best_word.root_word)?
                      <div>
                          <span className="wordRole">{translator._tr('FromRoot')}</span> <span>{props.wordState.best_word.root_word}</span>
                      </div>
                    :null
                  }
                </div>


                {
                  (!!props.wordState.best_word.translations && props.wordState.best_word.translations.length)?
                    props.wordState.best_word.translations.map((data, index) => (
                      <Section key={index} tr_data={props.tr_data} data={data} />
                    )):null
                }


                {
                  (!!props.wordState.same_roots && props.wordState.same_roots.length)?
                  <div className={styles.sameRoots}>
                      <div className={styles.title}>
                        <h3>{translator._tr('RelatedWords')}</h3>
                      </div>
                      <div className={styles.list}>
                        {props.wordState.same_roots.map((data, index) => (
                          <div key={index} className={styles.sameRootWord} onClick={()=> props._callBack(data.word)}>
                            <GlobeText eriocode={fromTr.eriocode}>{data.word}</GlobeText>
                          </div>
                        ))}
                      </div>
                  </div>:null
                }


            </div>: <div className={`${styles.bestWord} ${(props.wordState.showWord && styles.showWord)}`}>
                        <h3>{translator._tr('wordNotFoundTitle')}</h3>
                        <p>{translator._tr('wordNotFoundComment')}</p>
                    </div>
            }
            {/* =============================== */}

            {
            (!!props.wordState.similar_list && props.wordState.similar_list.length)?
                <div className={`${styles.similarWordBox} ${(props.wordState.showWord && styles.showWord)}`}>
                    <header><h3>{translator._tr('SimilarWords')}</h3></header>
                    {
                        props.wordState.similar_list.map((data, index) => (
                          <SimilarWord key={index+toTr.key} data={data} tr_data={props.tr_data} _callBack={props._callBack}/>
                        ))
                    }
                </div>: null
            }

      </word-result>
    );

}


export default WordResult
