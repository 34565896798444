
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { langsList as trData } from '../utils/Translate/supportedLang';
import { DataContext } from '../utils/global/GlobalContext';
import { FiX } from 'react-icons/fi';
import styles from './ChangeTr.module.scss'; 

const ChangeTr = (props) => {

    const {translator, isMob, fromTr, setFromTr, toTr, setToTr} = useContext(DataContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showLangs, setShowLangs] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(()=>{
        setShowLangs(searchParams.get('li'));
    },[searchParams]);
      
    useEffect(() => {
        const handleClickOutside = (event)=>{
          if (wrapperRef.current && !wrapperRef.current.contains(event.target) && showLangs){
              showModal(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showLangs]);

    const openModal = ()=>{
        props.setKB(false);
        showModal(!showLangs);
    }

    const setFromLang = (lang)=>{
        props.clearInput();
        setFromTr(lang);
    }

    const setToLang = (lang) => {
        closeModal();
        setToTr(lang);
    }

    const closeModal = ()=>{
        props.setKB(true);
        showModal(false);
    }


    const showModal = (show) =>{
        if(isMob){
          if (show) {
            searchParams.set("li", true);
            searchParams.delete('kb');
            setSearchParams(searchParams);
          } else {
            searchParams.set("kb", true);
            searchParams.delete('li');
            setSearchParams(searchParams);
          }
        }
        setShowLangs(show);
      }

    return(
        <change-tr>
            <div ref={wrapperRef}>
                <div className={`${styles.trBtn} ${showLangs?styles.active:''}`} onClick={openModal}>
                    <span>{fromTr.icon}</span>
                    <span>{'>'}</span>
                    <span>{toTr.icon}</span>
                </div>
                <div className={`${styles.modal}`}>
                    <div className={`${styles.box} ${showLangs?styles.showLangs:''}`} >
                        <div className={styles.mHead}>
                            <span onClick={()=>showModal(false)}><FiX/></span>
                            
                        </div>
                        <div className={styles.langsList}>
                            <div className={styles.list}>
                                {trData.map((lang, i)=>(
                                    <span key={i} onClick={()=>setFromLang(lang)} className={lang==fromTr?styles.active:''}>{lang.title}</span>
                                ))}
                            </div>
                            <div className={styles.arrow}>{translator._tr('toLang')}</div>
                            <div className={styles.list}>
                                {trData.map((lang, i)=>(
                                    <span key={i} onClick={()=>setToLang(lang)} className={lang==toTr?styles.active:''}>{lang.title}</span>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </change-tr>
    )
}

// export default memo(ChangeTr, (prePosps, nextPos)=>
//     (prePosps.setKB == nextPos.setKB)
// );
export default ChangeTr;