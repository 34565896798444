import React, { useContext } from 'react';
import { DataContext } from '../utils/global/GlobalContext';
import SearchSection from './subComponents/SearchSection'

// import {is_rtl, is_unicode} from '../../utils/Lang/lang'
// import Footer from './Footer'
// import 'normalize.css/normalize.css'
import styles from './MainPage.module.scss';




function MainPage(props) {

    const {translator} = useContext(DataContext);
    // let current_lang = i18next.language
    // const { t, i18n } = useTranslation();
    // if (!!props.match.params.lang) {
    //   if (current_lang != props.match.params.lang) {
    //     current_lang = props.match.params.lang
    //     i18n.changeLanguage(props.match.params.lang);
    //   }
    // }else {

    // }
    // let is_rtl = (current_lang == 'uCLC')?"-rtl":""
    // if (is_rtl(current_lang)) {
    //   document.body.style.direction = "rtl";
    // }
    // if (is_unicode(current_lang)) {
    //   document.body.style.fontFamily = ['ardeshir', 'roboto', 'sahel'];
    // }

    return (

      <main-page>

          <div className={`${styles.section} ${(translator.isRtl())?styles.rtl:''} ${(translator.isUnicode())?styles.unicode:''}`}>
                <SearchSection />
          </div>

      </main-page>


    );

}


export default MainPage
